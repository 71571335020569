<script>
import {redirectToLogin} from "@/oauth"

function extractFromPath(pathMatch, name) {
  let field = name + "=";
  let start = pathMatch.indexOf(field);
  if (start < 0)
    return undefined;
  start += field.length;

  let end = pathMatch.indexOf("&", start);
  if (end < 0)
    return undefined;

  return pathMatch.substring(start, end > 0 ? end : pathMatch.length);
}

export default {
  props: ['pathMatch'],
  async beforeMount() {
    let token = extractFromPath(this.pathMatch,"id_token");
    let expiresIn = extractFromPath(this.pathMatch,"expires_in");
    let error = extractFromPath(this.pathMatch,"error");
    if (error && error === "login_required") {
      redirectToLogin({silent: false, originalPath: this.$router.currentRoute.fullPath});
    } else {
      await this.$store.dispatch('loadUserData', {token, expiresIn});
      let originalRoute = localStorage.getItem("originalRoute");
      localStorage.removeItem("originalRoute");
      // Only update the router if this was *not* a silent authentication
      if (!window.parent || window.parent === window) {
        await this.$router.replace({
          path: originalRoute || "/"
        });
      }
    }
  },
  render() {
    return undefined;
  }
};
</script>